<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
          <h4>{{ Object.entries(this.data).length === 0 ? "AJOUTER UN" : "MODIFICATION DU" }} BÉNÉFICIAIRE </h4>
          <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <div v-if="is_admin">
          <p class="pt-4"> Prospects <b style="color: #ff6141" >*</b> </p>
          <vs-select
            v-model="input.parent"
            autocomplete
            class="mt-5 w-full"
            name="prospect"
            v-validate="'required'">
            <vs-select-item :key="item" :value="item.id" :text="item.username" v-for="item in prospects" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('prospect')">{{ errors.first('prospect') }}</span>
          </div>

          <p> Nom <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            name="last_name"
            v-model="input.last_name"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

          <p class="pt-4"> Prénoms <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            name="first_name"
            v-model="input.first_name"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>

          <p class="pt-4"> Sexe <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="gender"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="input.gender"
            :options="genders">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first('gender') }}</span>

          <p class="pt-4"> Date de naissance <b style="color: #ff6141" >*</b> </p>
          <datepicker
            label-placeholder="Select Date"
            v-model="input.birthDate"
            v-validate="'required'"
            name="birthDate"
            class="w-full"
            ></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('birthDate')">{{ errors.first('birthDate') }}</span>

          <p  class="pt-4">Téléphone <b style="color: #ff6141" >*</b> </p>
          <vue-tel-input
            v-on:country-changed="countryChanged"
            inputOptions.placeholder="Telephone"
            :selectedCountryCode="true"
            v-model="input.phoneNumber"
            data-vv-validate-on="blur"
            v-validate="'required|min:8|max:16'"
          >
          </vue-tel-input>
          <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{ errors.first('phoneNumber') }}</span>

          <vs-select v-model="input.country" autocomplete label="Pays" class="mt-5 w-full" name="pays" v-validate="'required'">
            <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in countries" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('pays')">{{ errors.first('pays') }}</span>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            label="Ville"
            name="Ville"
            v-model="input.city"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('Ville')">{{ errors.first('Ville') }}</span>
        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="Prospect_validate">Soumettre</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
      </div>
  </vs-sidebar>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
let is_admin = false
const user_role = JSON.parse(localStorage.getItem('userInfo')).role
if (user_role === 'admin') {
  is_admin = true
}
const dict = {
  custom: {
    last_name: {
      required: 'Le champ nom est obligatoire'
    },
    first_name: {
      required: 'Le champ prénoms est obligatoire'
    },
    birthDate: {
      required: 'Le champ date de naissance est obligatoire'
    },
    gender: {
      required: 'Le champ sexe est obligatoire'
    },
    prospect: {
      required: 'Le champ prospect est obligatoire'
    },
    country: {
      required: 'Le champ pays est obligatoire'
    },
    city: {
      required: 'Le champ ville est obligatoire'
    },
    phoneNumber: {
      required: 'Le champ téléphone est obligatoire'
    }
  }
}
Validator.localize('en', dict)

const input_tempon = {
  // username: '',
  // email: '',
  parent:'',
  first_name: '',
  last_name: '',
  birthDate: '',
  gender: '',
  country: '',
  phoneNumber: '',
  city: ''
  // cni: ''
}

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  data () {
    return {
      genders: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      ],
      is_admin,
      prospects: [],
      countries: [],
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.input = JSON.parse(JSON.stringify(this.data))

        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    countryChanged (country) {
      const countryCode = country.dialCode
      // this.input.phoneNumber = '+'+country.dialCode
      this.$http.post('code-country/', { countryCode })
        .then((response) => {
          this.input.country = response.data[0].id
          // console.log("voir le pays",response.data[0].name)
        })
        .catch(() => {})

    },
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
    },
    Prospect_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    async submitData () {
      // this.$vs.loading()
      const input = JSON.parse(JSON.stringify(this.input))
      input.birthDate = this.$store.state.convertDateTime(new Date(input.birthDate)).date
      console.log('voir', input)
      let url = 'recipients/'
      let methods = 'post'
      const message = {
        error: 'Votre enrégistrement à échouer.',
        success: 'Le bénéficiaire est enrégistré avec succès.',
        titre: 'ENREGISTREMENT'
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = 'Le bénéficiaire est modifié avec succès.'
        message.titre = 'MODIFICATION'

      }

      this.$http[methods](url, input)
        .then(() => {
          // this.countries = response.data
          window.getProspect.getAllRecipients()
          this.$vs.notify({
            title: message.titre,
            text: message.success,
            color: 'success',
            position:'top-right'
          })
          // window.getPrendTaCom.success(message.success, response)
          // this.$vs.loading.close()
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {

          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]

            if (item === 'first_name') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Le champs prénom est vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'last_name') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Le champs nom est vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'birthDate') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Le champs date de naissance est vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'gender') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Le champs sexe est vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'country') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Le champs pays est vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'city') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Le champs ville est vide',
                color: 'warning',
                position:'top-center'
              })
            } else if (item === 'phoneNumber') {
              this.$vs.notify({
                time:4000,
                title: 'ATTENTION',
                text: 'Le champs téléphone est vide',
                color: 'warning',
                position:'top-center'
              })
            }


          }
          this.$vs.notify({
            time:8000,
            title: 'ENREGISTREMENT',
            text: 'L\'enregistrement a écchoué',
            color: 'danger',
            position:'top-right'
          })
        })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    getAllContries () {
      //delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    }
  },
  created () {
    this.$http.get('prospects/')
      .then((response) => { this.prospects = response.data })
      .catch((error) => { console.log(error) })
    this.getAllContries()

  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
