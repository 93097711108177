<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card ref="filterCard" title="Filtre" class="user-list-filters mb-4" actionButtons @refresh="resetColFilters" >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-2" > Pays </p>
          <v-select :options="countries" :reduce="rep => rep.id" :clearable="true" label="name" v-model="country" class="w-full"/>
        </div>
      </div>
    </vx-card>
    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="recipients">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Ajouter</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ recipients.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : recipients.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="pays">pays</vs-th>
        <vs-th sort-key="ville">ville</vs-th>
        <vs-th sort-key="username">Bénéficiaire</vs-th>
        <vs-th sort-key="gender">Sexe</vs-th>
        <vs-th sort-key="birthDate">Date de naissance</vs-th>
        <vs-th sort-key="birthDate">Téléphone</vs-th>
        <vs-th>Action</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td hidden >
                <p hidden class="product-name font-medium truncate">{{ tr.parent.username}}</p>
              </vs-td>

              <vs-td>
                <p class="product-pays">{{ tr.country.name}}</p>
              </vs-td>

              <vs-td>
                <p class="product-ville">{{ tr.city}}</p>
              </vs-td>

              <vs-td>
                <p class="product-username">{{ tr.full_name}}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ sexeObject[tr.gender] }}</p>
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor()" class="product-order-status">{{ tr.birthDate }}</vs-chip>
              </vs-td>

              <vs-td>
                <p class="product-username">{{ tr.phoneNumber}}</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <div class="flex">

                  <vx-tooltip text="Détail" color="primary">
                    <feather-icon
                      style="color: #7367F0"
                      icon="EyeIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="mr-2"
                      @click="activePrompt2 = true" v-on:click="openPlayerDialog(tr)"
                    />
                  </vx-tooltip>

                  <vx-tooltip text="Modifier" color="warning">
                    <feather-icon
                      style="color: #FF9F43"
                      icon="EditIcon"
                      svgClasses="w-5 h-5 hover:text-warning stroke-current"
                      class="mr-2"
                      @click="editData(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Supprimer" color="danger">
                    <feather-icon
                      @click="deleteData(tr.id)"
                      style="color: red"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 hover:text-danger stroke-current"
                      class="mr-2"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
    </vs-table>

    <vs-prompt title="DÉTAILS DU BENEFICIAIRE" buttons-hidden @close="close" :active.sync="activePrompt2">
      <div class="con-exemple-prompt" v-if="selectedPlayer">
        <vs-input readonly label="PROSPECT"  v-model=" selectedPlayer.parent.username " class="mt-4 mb-2 w-full" />
        <vs-input readonly label="EMAIL DU PROSPECT"  v-model=" selectedPlayer.parent.email" class="mt-4 mb-2 w-full" />
        <vs-input readonly label="TELEPHONE DU PROSPECT"  v-model=" selectedPlayer.parent.phoneNumber " class="mt-4 mb-2 w-full" />
        <vs-input readonly label="NOM DU BENEFICIAIRE"  v-model=" selectedPlayer.last_name" class="mt-4 mb-2 w-full" />
        <vs-input readonly label="TELEPHONE DU BENEFICIAIRE"  v-model=" selectedPlayer.phoneNumber" class="mt-4 mb-2 w-full" />
        <vs-input readonly label="PAYS DU BENEFICIAIRE"  v-model=" selectedPlayer.country.name" class="mt-4 mb-2 w-full" />
        <vs-input readonly label="BENEFICIAIRE"  v-model="selectedPlayer.full_name" class="mt-4 mb-2 w-full" />
<!--        <vs-input readonly label="PARCK DU BENEFICIAIRE"  v-model="selectedPlayer.userHasPackRecipients || AUCUN" class="mt-4 mb-2 w-full" />-->
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import DataViewSidebar from './DataViewSidebar.vue'
import moduleDataList from '@/store/data-list/moduleDataList.js'

const color_array = ['warning', 'success', 'danger', 'primary']
let iopi = 0

export default {
  components: {
    DataViewSidebar
  },
  data () {
    return {
      sexeObject: {
        male: 'Masculin',
        female: 'Féminin'
      },
      recipients: [],
      country:'',
      countries: [],
      selected: [],
      itemsPerPage: 4,
      isMounted: false,
      selectedPlayer:undefined,
      activePrompt2:false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },
  watch: {
    country (value) {
      if (value) {
        this.getAllRecipientsFilter(value)
      } else {
        this.getAllRecipients()
      }
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.recipients.length
    }
  },
  methods: {
    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    getAllRecipientsFilter (country) {
      this.$vs.loading()
      this.$http.post('recipient-by-country/', { country })
        .then((response) => {
          this.recipients = response.data
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log('error: ', error)
          this.$vs.loading.close()
        })
    },

    resetColFilters () {
      this.$refs.filterCard.removeRefreshAnimation()
    },
    deleteData (id) {
      const base_self = this
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`recipients/${id}/`)
            .then(response => {
              base_self.getAllRecipients()
              window.getPrendTaCom.success('Le bénéficaire est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression du bénéficaire a échouée.' })
            })
        }
      })

    },
    editData (data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.getAllRecipients()
      this.sidebarData = data
      this.sidebarData.country = data.country.id
      this.sidebarData.parent = this.sidebarData.parent.id
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor () {
      const libelle = color_array[iopi]

      if ((iopi + 1) < color_array.length) {
        iopi++
      } else {
        iopi = 0
      }
      return libelle
    },
    getPopularityColor (num) {
      if (num > 90)  return 'success'
      if (num > 70)  return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50)  return 'danger'
      return 'primary'
    },
    openPlayerDialog (player) {
      this.selectedPlayer = player

    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
    getAllRecipients () {
      this.$vs.loading()
      this.$http.get('recipients/')
        .then((response) => {
          this.recipients = response.data
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log('error: ', error)
          this.$vs.loading.close()
        })
    },
    getAllContries () {
      //delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    }
  },
  created () {
    window.getProspect = this
    this.getAllRecipients()
    this.getAllContries()

    if (!moduleDataList.isRegistered) {
      this.$store.registerModule('dataList', moduleDataList)
      moduleDataList.isRegistered = true
    }
    this.$store.dispatch('dataList/fetchDataListItems')

  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
